<template>
<div class="container" v-loading="loading">
    <div class="date-picker">
        <el-date-picker type="daterange" v-model="search.time" value-format='timestamp' unlink-panels
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            @change='handleDateRangeChange'>
        </el-date-picker>
    </div>
    <div class="analysis-block">
        <colorful-title title="学习数据概况"/>
        <el-row class="content overview">
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">总订阅人数(截至昨日)</div>
                <div class="count">{{overview.total_count}}</div>
                <el-tooltip placement="top" :content="`总订阅人数(截至昨日):${overview.total_count}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">总学习人数</div>
                <div class="count">{{overview.study_count}}</div>
                <el-tooltip placement="top" :content="`总学习人数:${overview.study_count}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">总完成人数</div>
                <div class="count">{{overview.finish_count}}</div>
                <el-tooltip placement="top" :content="`总完成人数:${overview.finish_count}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">完课率累计</div>
                <div class="count">{{overview.finish_rate}}%</div>
                <el-tooltip placement="top" :content="`完课率累计:${overview.finish_rate}%`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
        </el-row>
    </div>
    <div class="analysis-block">
        <div class="title">
            <colorful-title title="学习趋势图" hint='学习趋势图'/>
        </div>
        <div class="content">
            <v-chart :options="trendOptions1" class="trend-chart"></v-chart>
        </div>
    </div>
    <div class="analysis-block">
        <div class="title">
            <colorful-title title="完课率趋势图" hint='完课率趋势图'/>
        </div>
        <div class="content">
            <v-chart :options="trendOptions2" class="trend-chart"></v-chart>
        </div>
    </div>
    <div class="analysis-block">
        <div class="title">
            <colorful-title title="学习详情" hint='学习详情'/>
        </div>
        <div class="content">
            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" :row-key="(row) => row.id">
                    <el-table-column label="用户名" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.username}}
                        </template>
                    </el-table-column>

                    <el-table-column label="来源" min-width="100" :show-overflow-tooltip="true" align="center">
                        <span slot-scope="scope" class="text-colourful">
                            {{scope.row.from || 'h5'}}
                        </span>
                    </el-table-column>
                    <el-table-column label="累计学习时长" min-width="120" align="center">
                        <template slot-scope="scope">
                            {{scope.row.time / 60}}
                        </template>
                    </el-table-column>
                    <el-table-column label="学习进度" min-width="120" align="center">
                        <template slot-scope="scope">
                            <el-progress :percentage="scope.row.progress || 0" :status="scope.row.status | learnStatusFilter"></el-progress>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="完成时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="prev, pager, next" background
                    @current-change="pageChange" :current-page="currentPage"
                    :total="total" :page-size="10">
                </el-pagination>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Core from '@/core';
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/grid";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";

const colors = ['#1890FF', '#2FC25B'];

export default {
    components: {
        NoData: () => import('@/components/Empty.vue'),
        ColorfulTitle: () => import('@/components/common/ColorfulTitle'),
        "v-chart": ECharts,
    },
    props: {
        id: {}
    },
    data() {
        return {
            loading: true,
            search: {
                time: [],
            },
            overview: {
                total_count: 0,  // 总订阅人数
                study_count: 0,  // 总学习人数
                finish_count: 0, // 总完成人数
                finish_rate: 0,  // 完课率累计
            },
            trendOptions1: {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    textStyle: {
                        fontSize: 14,
                    }
                },
                grid: {
                    width: 'auto',
                    show: false,
                    containLabel: false,
                    left: '5%',
                    right: '5%'
                },
                legend: {
                    data: ['学习人数', '人均学习时长(分钟)']
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#EEF2F5',
                            }
                        },
                        axisLabel: {
                            color: "rgba(0, 0, 0, 0.65)"
                        },
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            label: {
                                backgroundColor: '#333',
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '{point|}{text|学习人数}',
                        min: 0,
                        max: 250,
                        position: 'left',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            // align: 'left',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[0],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 0, 0, 4]

                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                    },
                    {
                        type: 'value',
                        name: '{point|}{text|人均学习时长(分钟)}',
                        min: 0,
                        max: 250,
                        position: 'right',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            // align: 'right',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[1],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 66, 0, 4]
                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                    },
                ],
                series: [
                    {
                        name: '学习人数',
                        type: 'line',
                        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
                    },
                    {
                        name: '人均学习时长(分钟)',
                        type: 'line',
                        yAxisIndex: 1,
                        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
                    },
                ]
            },
            trendOptions2: {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    textStyle: {
                        fontSize: 14,
                    }
                },
                grid: {
                    width: 'auto',
                    show: false,
                    containLabel: false,
                    left: '5%',
                    right: '5%'
                },
                legend: {
                    data: ['完成学习人数', '完课率']
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#EEF2F5',
                            }
                        },
                        axisLabel: {
                            color: "rgba(0, 0, 0, 0.65)"
                        },
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            label: {
                                backgroundColor: '#333',
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '{point|}{text|完成学习人数}',
                        min: 0,
                        max: 250,
                        position: 'left',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            align: 'left',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[0],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 0, 0, 4]
                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                    },
                    {
                        type: 'value',
                        name: '{point|}{text|完课率}',
                        min: 0,
                        max: 100,
                        position: 'right',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            // align: 'right',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[1],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 0, 0, 4]
                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    },
                ],
                series: [
                    {
                        name: '完成学习人数',
                        type: 'line',
                        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
                    },
                    {
                        name: '完课率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 75.6, 82.2, 48.7, 18.8, 6.0, 2.3],
                    },
                ]
            },

            total: 0,
            currentPage: 1,
            tableData: [],
        }
    },
    created() {},
    mounted() {
        this.getOverviewData();
        this.getTableData();
    },
    methods: {
        handleDateRangeChange(val) {
            console.log('handleDateRangeChange val:', val)
            this.getOverviewData();
        },
        getOverviewData() {
            this.loading = true
            Core.Api.Course.courseStat(
                this.id,
                this.search.time[0] / 1000 || '',
                this.search.time[1] / 1000 || '',
            ).then(res => {
                console.log('getOverviewData res:', res)
                this.overview = res;
            }).finally(() => {
                this.loading = false
            })
        },
        getTableData() {
            this.loading = true
            Core.Api.Course.learningProgressList(
                this.id,
                this.currentPage,
                10,
            ).then(res => {
                console.log('getTableData res:', res)
            }).finally(() => {
                this.loading = false
            })
        },
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    .date-picker {
        margin-bottom: 20px;
    }
    .analysis-block {
        > .content {
            margin-top: 20px;
            .page-container {
                display: flex;
                justify-content: center;
                padding: 20px 0;
            }
        }
        .trend-chart {
            width: 100%;
            height: 380px;
        }
    }
}
</style>